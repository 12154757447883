import React, { useMemo } from "react";
import { RenderList } from "../Markdown";
import { MarkdownDeclaration, RenderElement } from "../protocol";
import { RowProps } from "../Row";
import { RenderFormats, cx, renderValue } from "./renderValue";
import { InfoIcon } from "./InfoIcon";
import { MaybeLabel } from "./MaybeLabel";
import { useEvent } from "../server_hooks";

type SimpleContent = string | number | Date | null;
type AllContent = MarkdownDeclaration | SimpleContent | SimpleContent[];

type Info = MarkdownDeclaration | string;

type Props = {
  content: AllContent;
  label?: string;
  size?: "xs" | "s" | "m" | "l" | "xl";
  align?: "start" | "center" | "end";
  color?: "success" | "error" | "muted" | "neutral";
  appearance?: "card" | "well" | "inverse";
  // TODO: icon?
  format?: RenderFormats;
  info?: Info;
} & RowProps;

const CLASSES = {
  size: {
    xs: "txt-size-xs",
    s: "txt-size-s",
    m: "",
    l: "txt-size-l",
    xl: "txt-size-xl",
  },
  color: {
    muted: "txt-color-muted",
    good: " txt-color-success",
    success: "txt-color-success",
    increase: "txt-color-success",
    bad: "txt-color-error",
    error: "txt-color-error",
    decrease: "txt-color-error",
    neutral: "txt-color-neutral",
    unchanged: "txt-color-neutral",
    nochange: "txt-color-neutral",
    info: "", // TODO
    warning: "", // TODO
    "cat-1": "color-cat-1",
    "cat-2": "color-cat-2",
    "cat-3": "color-cat-3",
    "cat-4": "color-cat-4",
    "cat-5": "color-cat-5",
    "cat-6": "color-cat-6",
    "cat-7": "color-cat-7",
    "cat-8": "color-cat-8",
    "cat-9": "color-cat-9",
  },
  align: {
    start: "",
    center: "txt-align-center",
    end: "txt-align-end",
  },
  appearance: {
    button: "",
    button_outline: "outline",
    button_secondary: "secondary",
    button_muted: "muted",
  },
};

type C = {
  size?: keyof typeof CLASSES.size;
  color?: keyof typeof CLASSES.color;
  align?: keyof typeof CLASSES.align;
  appearance?: keyof typeof CLASSES.appearance;
};

export function txt_cx(obj: C, ...other: string[]) {
  return cx(
    ...other,

    ...new Set(
      Object.entries(obj)
        .flatMap(([k, v]) => CLASSES[k][v])
        .filter((x) => !!x)
        .flatMap((x) => x.split(/\s+/))
    )
  );
}

export function isMarkdown(
  content: AllContent
): content is MarkdownDeclaration {
  return (
    content != null &&
    typeof content === "object" &&
    "args" in content &&
    "unparsed" in content
  );
}

export function TxtWidget(props: Props) {
  const {
    content,
    size,
    color,
    align,
    format,
    rowHasLabel,
    inRow,
    label,
    info,
    appearance,
  } = props;

  const onClick = useEvent("click");

  const c = (e) => onClick(true, true);

  const rendered = useMemo(() => {
    if (isMarkdown(content)) {
      const el: RenderElement = { type: "md", data: content };
      const r = <RenderList content={[el]} />;
      if (r == null) return null;

      const cs = txt_cx({ size, color, align }, "txt", "markdown");

      return inRow ? (
        <div onClick={c} className={cs}>
          {r}
        </div>
      ) : (
        <span onClick={c} className={cs}>
          {r}
        </span>
      );
    } else {
      const r = renderValue(content, format);
      if (r == null) return null;
      const cs = txt_cx({ size, color, align }, "txt");
      return (
        <div onClick={c} className={cs}>
          {r}
        </div>
      );
    }
  }, [content, size, color, align, format, inRow]);

  const x = info ? (
    <div className="txt_info">
      {rendered}
      <InfoIcon content={info} />
    </div>
  ) : (
    rendered
  );

  return (
    <Appearance appearance={appearance}>
      {x == null ? (
        <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      ) : MaybeLabel({ label, rowHasLabel }) ? (
        <div>
          <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
          {x}
        </div>
      ) : (
        x
      )}
    </Appearance>
  );
}

function Appearance({ appearance, children }) {
  switch (appearance) {
    case "card":
      return <article>{children}</article>;
    case "well":
      return <div className="container_well">{children}</div>;
  }

  return children;
}
