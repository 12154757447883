import {
  ClientUpdate,
  ConsumerData,
  CSeq,
  P2CMethodMsg,
  P2CRegionCreatedMsg,
  P2CRegionUpdateMsg,
  Path,
  RegionType,
  RenderElement,
} from "./protocol";
import {
  DefinedUpdatable,
  Updatable,
  UpdatableInstance,
} from "./useUpdatableProps";
import { Draft, createDraft, finishDraft } from "immer";
import { ConsumerManager } from "./ConsumerManager";
import { RegionVals, ValInfo } from "./RegionVals";
import { PathNode } from "./PathNode";
import { Subject } from "rxjs";
import { applyPatch } from "./shared/patch";

const EMPTY_MAP: ReadonlyMap<any, any> = new Map();

export type WidgetStructure = Readonly<{
  pathNode: PathNode;
  tag: string;
  vals: ReadonlyMap<string, ValInfo>;
  props: { [Key in string]: any };
  children: ReadonlyMap<string, WidgetStructure> | null;
  callMethod: (methodName: string, args: any) => void;
  setMethodHandler: (methodName: string, handler: (args: any) => void) => void;
}>;

export type RegionStructure = Readonly<{
  rSeq: number;
  cSeq: number;

  widgets: ReadonlyMap<string, WidgetStructure>;
  body: RenderElement[];

  /** a map from the regionId to the id passed by the dev and used by the markdown */
  launchedChildren: ReadonlyMap<string, string>;
  /** The regionId of whatever is covering this region */
  coverId: null | string;
}>;

export type RegionUpdator = (
  structure: Draft<Updatable<RegionStructure>>
) => void;

export class RegionState {
  readonly type: RegionType;
  readonly parentId: undefined | string;
  readonly structure: UpdatableInstance<RegionStructure>;
  readonly vals: RegionVals;

  readonly updateRegion: (
    path: Path,
    key: string,
    val: any,
    isValid: boolean,
    debounce: number
  ) => CSeq;

  private _draft: null | Draft<DefinedUpdatable<RegionStructure>> = null;
  private readonly _treeUpdates: Subject<ClientUpdate>;

  constructor(
    manager: ConsumerManager,
    { regionId, data }: P2CRegionCreatedMsg
  ) {
    this.vals = new RegionVals(this, manager.deserialize);

    this.updateRegion = (path, key, val, isValid, debounce) =>
      manager.runUpdate(
        regionId,
        this.structure.content.val.rSeq,
        path,
        key,
        val,
        isValid,
        debounce
      );

    this.type = data.type;
    this.parentId = data.parentId;
    this.structure = new UpdatableInstance<RegionStructure>({
      pending: true,
      val: {
        rSeq: 0,
        cSeq: 0,
        widgets: new Map(),
        body: [],
        launchedChildren: EMPTY_MAP,
        coverId: null,
      },
    });

    this.structure.addListener((update) => {
      console.log("Region Updated", update);
    });
  }

  private draft(): Draft<DefinedUpdatable<RegionStructure>> {
    let draft = this._draft;
    if (!draft) {
      draft = this._draft = createDraft(this.structure.content);
    }
    return draft;
  }

  addChild(msg: P2CRegionCreatedMsg) {
    const {
      regionId,
      data: { type, refId },
    } = msg;

    const draft = this.draft().val;
    if (type === RegionType.Cover) {
      draft.coverId = regionId;
    } else {
      draft.launchedChildren.set(regionId, refId);
    }
  }

  removeChild(regionId: string) {
    const draft = this.draft().val;
    if (draft.coverId === regionId) {
      draft.coverId = null;
    } else {
      draft.launchedChildren.delete(regionId);
    }
  }

  currentStructure(): RegionStructure | Draft<RegionStructure> {
    return this._draft?.val ?? this.structure.content.val;
  }

  callMethod({ path, methodName, args }: P2CMethodMsg) {
    const getChildren = () => {
      return this.structure.content.val!.widgets;
    };

    const applyMethod = () => {
      let foundWidget: WidgetStructure | Draft<WidgetStructure> | undefined;
      let children = getChildren();

      for (let i = 0; i < path.length; ++i) {
        foundWidget = children.get(path[i]);
        if (!foundWidget) {
          console.error(
            `${path.join(
              "."
            )} widget not found while calling ${methodName} method - check your async rendering logic.`
          );
          return;
        }
        children = foundWidget.children!;
      }
      foundWidget?.callMethod(methodName, args);
    };

    if (getChildren().size > 0) {
      applyMethod();
    } else {
      // queueMicrotask is used to ensure that the method is called after the structure is populated
      queueMicrotask(applyMethod);
    }
  }

  handleServerUpdate(msg: P2CRegionUpdateMsg) {
    const draft = this.draft();

    switch (msg.type) {
      case "nochange": {
        draft.val.cSeq = msg.data.cSeq;

        // rSeq will always be defined, but including the if statement to handle
        // legacy clients
        if (msg.data.rSeq) {
          draft.val.rSeq = msg.data.rSeq;
        }
        break;
      }
      case "updating": {
        draft.pending = true;
        break;
      }
      case "updated": {
        draft.pending = false;

        const update: ClientUpdate = msg.isPatch
          ? applyPatch(this._lastUpdate, msg.data)
          : msg.data;

        this._lastUpdate = update;
        this.vals.crawlTree(draft.val, update);
        break;
      }
      case "set": {
        // todo: maybe investigate if we should do this
        // draft.val.rSeq = msg.update.rSeq;
        this.vals.setValAtPath(msg);
        break;
      }
    }
  }

  commitDraft() {
    const draft = this._draft;
    if (draft) {
      const updated = finishDraft(draft) as DefinedUpdatable<RegionStructure>;
      this._draft = null;

      // updateContent will ignore updates that do not change anything
      this.structure.updateContent(updated);
    }
  }

  updateTo(content: DefinedUpdatable<RegionStructure>) {
    this.structure.updateContent(content);
  }
}
