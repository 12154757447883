import React, { useContext } from "react";
import {
  FIXED_GAP,
  SPRING,
  VERTICAL_SEP,
  WidgetFlexData,
  WidgetGroupData,
  WidgetGroupEl,
  isWidgetId,
  GroupAlign,
} from "./doc";
import { WidgetContext } from "./server_hooks";
import { Widget } from "./widgets/Widget";
import { cx } from "./widgets/renderValue";
import flattenChildren from "react-keyed-flatten-children";

export type RowProps = {
  serverId: string;
  inRow?: boolean;
  rowHasLabel?: Boolean;
};

export function isButtonLike(tag: string) {
  switch (tag) {
    case "Button":
    case "Link":
    case "NavButton":
    case "Download":
      return true;
  }
  return false;
}

function inlineLabel(tag: string) {
  switch (tag) {
    case "Button":
    case "Link":
    case "Download":
    case "Table":
    case "Boolean":
      return true;
  }
  return false;
}

export function Row({ data }: { data: WidgetGroupData[] }) {
  const { widgets } = useContext(WidgetContext);

  // just the widgets
  const mdWidgets = data.filter((el) => el.type === WidgetGroupEl.Widget);

  // if we are rendering more than one widget
  const multi = mdWidgets.length > 1;

  // force row labels only if more than one widget
  // and any element has a label and skip inline labels.
  const rowHasLabel =
    multi &&
    mdWidgets
      .flatMap((el) =>
        el.type === WidgetGroupEl.Widget
          ? el.data.widgetId.trim().split(",").filter(isWidgetId)
          : []
      )
      .some((wid) => {
        const widget = widgets.get(wid);
        if (!widget) return false;
        if (inlineLabel(widget.tag)) return false;
        return widget.props.label != null;
      });

  const fragment: JSX.Element[] = [];

  // wrap buttons in a div so their layout is isolated from the
  // parent flex box layout
  let buttons: JSX.Element[] = [];

  let span = 1;
  let spring_key = 1;

  function flushButtons() {
    const len = buttons.length;
    if (len === 0) return;

    fragment.push(
      <div className={cx("button_group", len > 1 && `grid-span-${len}`)}>
        {buttons}
      </div>
    );

    buttons = [];
  }

  let has_grid_span = false;
  data.forEach((el, index) => {
    if (el.type === WidgetGroupEl.Spring) {
      flushButtons();
      fragment.push(<span className="spring" key={`spring_${spring_key++}`} />);

      return;
    }

    const { widgetId, mdLabel, align } = el.data;

    const bits = widgetId.trim().split(",");

    if (bits.length > 1) {
      flushButtons();

      const data = bits.map((bit): WidgetFlexData => {
        if (bit === "-") {
          return FIXED_GAP;
        } else if (bit === "~") {
          // TODO: should springs be allowed in flex?
          return SPRING;
        } else if (bit === "|") {
          return VERTICAL_SEP;
        } else {
          return {
            type: WidgetGroupEl.Widget,
            data: {
              widgetId: bit,
              mdLabel: undefined,
            },
          };
        }
      });

      fragment.push(
        <Flex
          align={align}
          data={data}
          inRow={multi}
          rowHasLabel={rowHasLabel}
          key={String(index)}
        />
      );
    } else {
      const widget = widgets.get(widgetId);

      // missing widgets are rendered a springs
      if (!widget) {
        flushButtons();

        fragment.push(
          <span className="spring" key={`spring_${spring_key++}`} />
        );

        return;
      }

      if (isButtonLike(widget.tag) && align === "default") {
        buttons.push(
          <Widget
            key={widgetId}
            id={widgetId}
            inRow={multi}
            rowHasLabel={rowHasLabel}
          />
        );
        return;
      }

      flushButtons();

      // consequitive widgetIds cause a span to form
      if (index + 1 < data.length) {
        const next = data[index + 1];
        if (
          next.type === WidgetGroupEl.Widget &&
          align === next.data.align &&
          widgetId === next.data.widgetId
        ) {
          span++;
          return;
        }
      }

      const widgetNode = (
        <Widget
          key={widgetId}
          id={widgetId}
          label={mdLabel}
          inRow={multi}
          rowHasLabel={rowHasLabel}
        />
      );

      if (isButtonLike(widget.tag)) {
        fragment.push(
          <div
            key={widgetId}
            className={cx(
              "button_group",
              span > 1 && `grid-span-${span}`,
              align !== "default" && `flex-align-${align}`
            )}
          >
            {widgetNode}
          </div>
        );
      } else if (
        span > 1 ||
        align !== "default" ||
        flattenChildren(widgetNode).length > 1
      ) {
        fragment.push(
          <div
            key={widgetId}
            className={cx(
              "grid-span",
              span > 1 && `grid-span-${span}`,
              align !== "default" && `grid-align-${align}`
            )}
          >
            {widgetNode}
          </div>
        );
        has_grid_span = true;
      } else {
        fragment.push(widgetNode);
      }

      span = 1;
    }
  });

  flushButtons();

  return has_grid_span || fragment.length > 1 ? (
    <div
      className={cx(
        "responsive",
        multi && "in_row",
        data.length > 3 && "wide_width"
      )}
      style={
        {
          "--grid-column-count": data.length,
        } as unknown as React.CSSProperties
      }
    >
      {fragment}
    </div>
  ) : (
    fragment[0]
  );
}

interface GridFragment {
  x: number;
  y: number;
  content: JSX.Element;
}

function sortByColumn(a: GridFragment, b: GridFragment) {
  if (a.x === b.x) {
    return a.y - b.y;
  }
  return a.x - b.x;
}

function sortByRow(a: GridFragment, b: GridFragment) {
  if (a.y === b.y) {
    return a.x - b.x;
  }
  return a.y - b.y;
}

export function Grid({ rows }: { rows: Array<WidgetGroupData[]> }) {
  const { widgets } = useContext(WidgetContext);

  const fragments: Array<GridFragment> = [];

  const spannedOverSlots = new Set<string>();

  const rowsWithRowSpans = new Set<number>();

  let maxCols = 0;

  rows.forEach((row, rowIndex) => {
    const rowNum = rowIndex + 1;

    const mdWidgets = row
      .flat(1)
      .filter((el) => el.type === WidgetGroupEl.Widget);

    // wrap buttons in a div so their layout is isolated from the
    // parent flex box layout
    let buttons: JSX.Element[] = [];

    let buttonBufferLocation: { row: number; col: number } | null = null;

    function flushButtons() {
      if (buttons.length === 0) return;
      if (buttonBufferLocation === null) {
        throw new Error(
          "Invariant: no button location specified when flushing a button"
        );
      }

      fragments.push({
        x: buttonBufferLocation.col,
        y: buttonBufferLocation.row,
        content: (
          <div
            key={`${buttonBufferLocation.row},${buttonBufferLocation.col}`}
            className="button_group"
            style={{
              gridArea: `${buttonBufferLocation.row} / ${buttonBufferLocation.col} / span 1 / span ${buttons.length}`,
            }}
          >
            {buttons}
          </div>
        ),
      });

      buttons = [];
      buttonBufferLocation = null;
    }

    // if we are rendering more than one widget
    const multi = mdWidgets.length > 1;

    // traverse the widgets on the row (skipping the ones who are spanned over)
    const rowHasLabel =
      multi &&
      row.some((el, index) => {
        const key = `${rowNum},${index + 1}`;
        if (spannedOverSlots.has(key)) {
          return false;
        }
        const slotWidgets =
          el.type === WidgetGroupEl.Widget
            ? el.data.widgetId.trim().split(",").filter(isWidgetId)
            : [];
        return slotWidgets.some((widgetId) => {
          const widget = widgets.get(widgetId);
          if (!widget) return false;
          if (inlineLabel(widget.tag)) return false;
          return widget.props.label != null;
        });
      });

    row.forEach((el, index) => {
      const colNum = index + 1;
      let colSpan = 1;
      let rowSpan = 1;

      function calculateSpans() {
        let nextIndex = index + 1;

        while (nextIndex < row.length) {
          const next = row[nextIndex];
          if (
            next.type === WidgetGroupEl.Widget &&
            widgetId === next.data.widgetId
          ) {
            colSpan++;
            spannedOverSlots.add(`${rowNum},${nextIndex + 1}`);
            nextIndex++;
          } else {
            break;
          }
        }

        let nextRowIndex = rowIndex + 1;
        while (nextRowIndex < rows.length) {
          const nextRow = rows[nextRowIndex];
          const itemsBelow = nextRow.slice(index, index + colSpan);
          if (
            itemsBelow.every(
              (el) =>
                el.type === WidgetGroupEl.Widget &&
                el.data.widgetId === widgetId
            )
          ) {
            for (let colIndex = index; colIndex < index + colSpan; colIndex++) {
              spannedOverSlots.add(`${nextRowIndex + 1},${colIndex + 1}`);
            }
            rowSpan++;
            nextRowIndex++;
          } else {
            break;
          }
        }

        if (rowSpan > 1) {
          for (let i = rowNum; i < rowNum + rowSpan; i++) {
            rowsWithRowSpans.add(i);
          }
        }
      }

      const key = `${rowNum},${colNum}`;

      maxCols = Math.max(maxCols, colNum);

      if (spannedOverSlots.has(key)) {
        return;
      }

      function cellStyle() {
        return {
          gridArea: `${rowNum} / ${colNum} / span ${rowSpan} / span ${colSpan}`,
        };
      }

      if (el.type === WidgetGroupEl.Spring) {
        flushButtons();
        fragments.push({
          x: colNum,
          y: rowNum,
          content: <span className="spring" key={key} style={cellStyle()} />,
        });
        return;
      }

      const { widgetId, mdLabel, align } = el.data;

      const bits = widgetId.trim().split(",");

      if (bits.length > 1) {
        flushButtons();

        const data = bits.map((bit): WidgetFlexData => {
          if (bit === "-") {
            return FIXED_GAP;
          } else if (bit === "~") {
            // TODO: should springs be allowed in flex?
            return SPRING;
          } else if (bit === "|") {
            return VERTICAL_SEP;
          } else {
            return {
              type: WidgetGroupEl.Widget,
              data: {
                widgetId: bit,
                mdLabel: undefined,
              },
            };
          }
        });

        // needs to be called before cellStyle
        calculateSpans();

        fragments.push({
          x: colNum,
          y: rowNum + rowSpan - 1,
          content: (
            <Flex
              data={data}
              rowHasLabel={rowHasLabel}
              inRow
              style={cellStyle()}
              key={key}
              align={align}
            />
          ),
        });
      } else {
        const widget = widgets.get(widgetId);

        // missing widgets are rendered a springs
        if (!widget) {
          flushButtons();
          fragments.push({
            x: colNum,
            y: rowNum,
            content: <span className="spring" key={key} style={cellStyle()} />,
          });
          return;
        }

        if (isButtonLike(widget.tag) && align === "default") {
          buttonBufferLocation ||= { row: rowNum, col: colNum };
          buttons.push(
            <Widget
              key={widgetId}
              id={widgetId}
              inRow={multi}
              rowHasLabel={rowHasLabel}
            />
          );
          return;
        } else {
          flushButtons();
        }

        const widgetNode = (
          <Widget
            key={widgetId}
            id={widgetId}
            label={mdLabel}
            inRow={multi}
            rowHasLabel={rowHasLabel}
          />
        );

        // needs to be called before cellStyle
        calculateSpans();

        fragments.push({
          x: colNum,
          y: rowNum + rowSpan - 1,
          content: (
            <div
              key={widgetId}
              style={cellStyle()}
              className={cx(
                isButtonLike(widget.tag) ? "button_group" : "grid-span",
                align !== "default" && `grid-align-${align}`
              )}
            >
              {widgetNode}
            </div>
          ),
        });
      }
    });

    flushButtons();
  });

  const jsx = fragments
    .sort((a, b) => {
      return rowsWithRowSpans.has(a.y) && rowsWithRowSpans.has(b.y)
        ? sortByColumn(a, b)
        : sortByRow(a, b);
    })
    .map(({ content }) => content);

  // TODO: if any rows are purely ~, row height = 1fr
  return (
    <div
      className={cx("widgets_grid", "in_row", maxCols > 3 ? "wide_width" : "")}
      style={{
        gridTemplateRows: `repeat(${rows.length - 1}, auto) 1fr`,
      }}
    >
      {jsx}
    </div>
  );
}

export function Flex({
  data,
  rowHasLabel = false,
  inRow = false,
  style,
  align,
}: {
  data: WidgetFlexData[];
  rowHasLabel?: boolean;
  inRow?: boolean;
  style?: React.CSSProperties;
  align?: GroupAlign;
}) {
  const { widgets } = useContext(WidgetContext);

  // just the widgets
  const mdWidgets = data.filter((el) => el.type === WidgetGroupEl.Widget);

  // if we are rendering more than one widget
  const multi = mdWidgets.length > 1;

  // need to force row labels only if more than one widget
  // need to skip ove buttons.
  // button labels are inline and don't count towards this
  // TODO: same with checkboxes :-(
  const anyHaveLabel =
    rowHasLabel ||
    (multi &&
      mdWidgets.some((el) => {
        if (el.type !== WidgetGroupEl.Widget) return false;
        const widget = widgets.get(el.data.widgetId);
        if (!widget) return false;
        if (inlineLabel(widget.tag)) return false;
        return el.data.mdLabel != null || widget.props.label != null;
      }));

  const fragment: JSX.Element[] = [];

  // wrap buttons in a div so their layout is isolated from the
  // parent flex box layout
  let buttons: JSX.Element[] = [];

  function flushButtons(index: string | number) {
    if (buttons.length === 0) return;
    fragment.push(
      <div className="button_group" key={`buttons_${index}`}>
        {buttons}
      </div>
    );
    buttons = [];
  }

  data.forEach((el, index) => {
    // missing widgets are rendered a springs
    if (el.type === WidgetGroupEl.Spring) {
      fragment.push(<div className="spring" key={String(index)} />);
      flushButtons(index);
      return;
    } else if (el.type === WidgetGroupEl.FixedGap) {
      fragment.push(<div className="row_gap" key={String(index)} />);
      flushButtons(index);
      return;
    } else if (el.type === WidgetGroupEl.VerticalSeparator) {
      fragment.push(<div className="vertical_sep" key={String(index)} />);
      flushButtons(index);
      return;
    }

    const { widgetId, mdLabel } = el.data;

    const widget = widgets.get(widgetId);

    if (!widget) {
      fragment.push(<div className="spring" key={String(index)} />);
      flushButtons(index);
      return;
    }

    if (isButtonLike(widget.tag)) {
      buttons.push(
        <Widget
          key={widgetId}
          id={widgetId}
          inRow={inRow || multi}
          rowHasLabel={anyHaveLabel}
        />
      );
      return;
    }

    flushButtons(index);

    fragment.push(
      <Widget
        key={widgetId}
        id={widgetId}
        label={mdLabel}
        inRow={inRow || multi}
        rowHasLabel={anyHaveLabel}
      />
    );
  });

  flushButtons("last");

  return (
    <div
      className={cx(
        "flex",
        "in_row",
        data.length > 3 ? "wide_width" : "",
        align && align !== "default" && `flex-align-${align}`
      )}
      style={style}
    >
      {fragment}
    </div>
  );
}
