import React, { Fragment, memo, useContext, useMemo } from "react";
import { MarkdownArgs, parseMd, parseMdWithWidgetSubs } from "./doc";
import { MarkdownDeclaration, RenderElement } from "./protocol";
import { WidgetStructure } from "./RegionState";
import { WidgetContext } from "./server_hooks";
import { Widget } from "./widgets/Widget";
import { isButtonLike } from "./Row";

export const EMPTY_WIDGET_MAP: ReadonlyMap<string, WidgetStructure> = new Map();

type MarkdownProps = {
  display?: MarkdownDeclaration;
};

export function Markdown({ display }: MarkdownProps) {
  const { widgets } = useContext(WidgetContext);
  const content = useMemo(
    (): RenderElement[] => (display ? [{ type: "md", data: display }] : []),
    [display]
  );

  return <RenderList content={content} widgets={widgets} />;
}

type RenderListProps = {
  content: RenderElement[];
  widgets?: ReadonlyMap<string, WidgetStructure>;
};

export const RenderList = memo(function RenderList(props: RenderListProps) {
  const referenced = new Set<string>();
  const declared = new Set<string>();
  const matchingMarkdown = new Map<string, number>();

  const noWidgetSubs = !props.widgets;
  const widgets = props.widgets || EMPTY_WIDGET_MAP;

  // wrap buttons in a div so their layout is isolated from the
  // parent flex box layout
  let buttons: string[] = [];

  function flushButtons() {
    if (buttons.length === 0) return;
    const rendered = buttons.map((widgetId) => (
      <Placeholder key={`w${widgetId}`} id={widgetId} referenced={referenced} />
    ));
    children.push(
      <div key={buttons.join(",")} className="button_group">
        {rendered}
      </div>
    );
    buttons = [];
  }

  const children: JSX.Element[] = [];
  props.content.forEach((el, index) => {
    switch (el.type) {
      case "string":
        flushButtons();

        children.push(<>{el.data}</>);
        break;
      case "md": {
        const { uniqUnparsedId, Comp, references } = noWidgetSubs
          ? parseMd(el.data.unparsed)
          : parseMdWithWidgetSubs(el.data.unparsed);

        references.forEach((widgetId) => {
          referenced.add(widgetId);
        });

        let key = uniqUnparsedId;

        flushButtons();

        // this is for a bit of a pathological case where the dev uses two of
        // the same markdown code in two different locations
        const matches = matchingMarkdown.get(uniqUnparsedId);
        if (matches) {
          key = `${matches}${uniqUnparsedId}`;
          matchingMarkdown.set(uniqUnparsedId, matches + 1);
        } else {
          matchingMarkdown.set(uniqUnparsedId, 1);
        }

        children.push(
          <MarkdownArgs.Provider key={key} value={el.data.args}>
            <Comp />
          </MarkdownArgs.Provider>
        );
        break;
      }
      case "placeholder": {
        const widgetId = el.data;
        declared.add(widgetId);
        // widget Ids starting with __sm (eg: __MENUBAR__ ) are specially handled
        if (referenced.has(widgetId)) return;
        if (widgetId.startsWith("__sm")) return;

        const widget = widgets.get(widgetId);

        if (widget && isButtonLike(widget.tag)) {
          buttons.push(widgetId);
        } else {
          flushButtons();

          children.push(
            <Placeholder
              key={`w${widgetId}`}
              id={widgetId}
              referenced={referenced}
            />
          );
        }
      }
    }
  });

  // any widgets we tacked on after the fact (launched subregions)
  props.widgets?.forEach((widget, widgetId) => {
    if (declared.has(widgetId)) return;
    if (referenced.has(widgetId)) return;

    if (isButtonLike(widget.tag)) {
      buttons.push(widgetId);
    } else {
      flushButtons();

      children.push(
        <Placeholder
          key={`w${widgetId}`}
          id={widgetId}
          referenced={referenced}
        />
      );
    }
  });

  // any remaining buttons
  flushButtons();

  return <Fragment>{children.filter(Boolean)}</Fragment>;
});

type PlaceholderProps = {
  id: string;
  referenced: ReadonlySet<string>;
};

/**
 * A component that will render the widget with the given id if that widget was
 * not placed by markdown.
 */
export function Placeholder({ id, referenced }: PlaceholderProps) {
  return referenced.has(id) ? null : <Widget id={id} />;
}
