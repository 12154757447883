// area, byte, bit, duration, temperature, length, volume, weight

export type UnitScaleTable<Unit = string> = Array<{
  to: number;
  size: number;
  unit: Unit;
}>;

export const byte_scales: UnitScaleTable = [
  { size: 1, to: 2 ** 10, unit: "byte" },
  { size: 2 ** 10, to: 2 ** 20, unit: "kilobyte" },
  { size: 2 ** 20, to: 2 ** 30, unit: "megabyte" },
  { size: 2 ** 30, to: 2 ** 40, unit: "gigabyte" },
  { size: 2 ** 40, to: 2 ** 50, unit: "terabyte" },
  { size: 2 ** 50, to: Infinity, unit: "petabyte" },
];

export const bit_scales: UnitScaleTable = [
  { size: 1, to: 2 ** 10, unit: "bit" },
  { size: 2 ** 10, to: 2 ** 20, unit: "kilobit" },
  { size: 2 ** 20, to: 2 ** 30, unit: "megabit" },
  { size: 2 ** 30, to: 2 ** 40, unit: "gigabit" },
  { size: 2 ** 40, to: Infinity, unit: "terabit" },
  // petabit is not supported
  // { size: 2 ** 50, to: , unit: "petabit" },
];

const second = 1;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;
const week = 7 * day;
const month = 30 * day;
const year = 365 * day;

export const duration_scales = [
  "years",
  "months",
  "days",
  "hours",
  "minutes",
  "seconds",
];

export const relative_time_scales: UnitScaleTable<Intl.RelativeTimeFormatUnit> =
  [
    { size: second, to: minute, unit: "second" },
    { size: minute, to: hour, unit: "minute" },
    { size: hour, to: day, unit: "hour" },
    { size: day, to: 10 * day, unit: "day" },
    { size: week, to: 8 * week, unit: "week" },
    { size: month, to: year, unit: "month" },
    { size: year, to: Infinity, unit: "year" },
  ];

export const length_scales: UnitScaleTable = [
  { size: 1e-4, to: 1e-3, unit: "millimeter" },
  { size: 1e-3, to: 1, unit: "centimeter" },
  { size: 1, to: 1000, unit: "meter" },
  { size: 1000, to: Infinity, unit: "kilometer" },
];

export const mass_scales: UnitScaleTable = [
  { size: 1e-6, to: 1e-3, unit: "milligram" },
  { size: 1e-3, to: 1, unit: "gram" },
  { size: 1, to: Infinity, unit: "kilogram" },
];

// from https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers
const units = {
  angle: ["degree"],
  area: ["acre", "hectare"],
  concentration: ["percent"],
  digital: [
    "bit",
    "byte",
    "kilobit",
    "kilobyte",
    "megabit",
    "megabyte",
    "gigabit",
    "gigabyte",
    "terabit",
    "terabyte",
    "petabyte",
  ],
  duration: [
    "millisecond",
    "second",
    "minute",
    "hour",
    "day",
    "week",
    "month",
    "year",
  ],
  length: [
    "millimeter",
    "centimeter",
    "meter",
    "kilometer",
    "inch",
    "foot",
    "yard",
    "mile",
    "mile-scandinavian",
  ],
  mass: ["gram", "kilogram", "ounce", "pound", "stone"],
  temperature: ["celsius", "fahrenheit"],
  volume: ["liter", "milliliter", "gallon", "fluid-ounce"],
};

// // eg: kilometer-per-hour
// export const compound_units = simple_units.flatMap((first) =>
//   simple_units.map((second) => `${first}_per_${second}`)
// );

// const number_units = Object.fromEntries(
//   [...simple_units, ...compound_units].flatMap((unit) =>
//     ["", "_narrow", "_long"].map((disp) => [`unit_${unit}${disp}`, true])
//   )
// );
