import React, { useState, useRef } from "react";

export function Copy({ children }) {
  const [copied, setCopied] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className="copy_container">
      <code
        className="copy_btn"
        onClick={(e) => {
          if (!ref.current) return;
          navigator.clipboard.writeText(ref.current.innerText);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }}
      >
        {copied ? "COPIED!" : "COPY"}
      </code>
      <div ref={ref}>{children}</div>
    </div>
  );
}
