import React, { useEffect, useState } from "react";
import stringify from "json-stringify-pretty-compact";
import { CheckSquare, Minus } from "react-feather";
import { Icon } from "./Icon";
import { ToolTip } from "./ToolTip";
import { Renderable } from "../Renderable";
import {
  byte_scales,
  relative_time_scales,
  length_scales,
  bit_scales,
  mass_scales,
  duration_scales,
} from "./units";
import { formatDuration, intervalToDuration } from "date-fns";
import { RenderList } from "../Markdown";
import { EMPTY_WIDGET_MAP, isMarkdown } from "./TxtWidget";

const EMPTY_ARRAY = [];

const CAT_COLOR_COUNT = 9;

export const ZW_SPACE = "\u{200B}";
function stopProp(e) {
  e.stopPropagation();
}

export function pad(x: number) {
  return x < 10 ? `0${x}` : x;
}

export type RenderFormats =
  | "pre"
  | "var"
  | "profile"
  | "pill"
  | "icon_label"
  | "$"
  | "%"
  | "progress"
  | "stacked_bar"
  | "0.0"
  | "0.00"
  | "0.000"
  | "0,0"
  | "action"
  | "link"
  | "_link"
  | "link_new_tab"
  | "link_same_tab"
  | "date_full"
  | "date_long"
  | "date_medium"
  | "date_short"
  | "date_time_full"
  | "date_time_long"
  | "date_time_medium"
  | "date_time_short"
  | "time_full"
  | "time_long"
  | "time_medium"
  | "time_short";

export type SMCatColor =
  | "cat-1"
  | "cat-2"
  | "cat-3"
  | "cat-4"
  | "cat-5"
  | "cat-6"
  | "cat-7"
  | "cat-8"
  | "cat-9";

export type SMSemanticColor =
  | "muted"
  | "success"
  | "error"
  | "info"
  | "warning";

export type SMNamedColor =
  | "red"
  | "blue"
  | "green"
  | "purple"
  | "orange"
  | "yellow"
  | "gray"
  | "pink"
  | "cyan";

export type SMColor = SMSemanticColor | SMCatColor | SMNamedColor;

const DATE_OPTIONS: Record<string, Intl.DateTimeFormatOptions> = {
  date_full: { dateStyle: "full" },
  date_long: { dateStyle: "long" },
  date_medium: { dateStyle: "medium" },
  date_short: { dateStyle: "short" },
  date_time_full: { dateStyle: "full", timeStyle: "full" },
  date_time_long: { dateStyle: "long", timeStyle: "long" },
  date_time_medium: { dateStyle: "medium", timeStyle: "medium" },
  date_time_short: { dateStyle: "short", timeStyle: "short" },
  time_full: { timeStyle: "full" },
  time_long: { timeStyle: "long" },
  time_medium: { timeStyle: "medium" },
  time_short: { timeStyle: "short" },
};

export function cx(
  ...list: (string | null | undefined | false)[]
): string | undefined {
  return list.filter((x) => x && x.toString().trim()).join(" ") || undefined;
}

export function debounce(fn: (...args: unknown[]) => void, delay = 300) {
  let timer: number;
  return (...arg: unknown[]) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => fn(...arg), delay);
  };
}

export function throttle(fn: (...args: unknown[]) => void, delay = 100) {
  let timer: number | null;

  return (...args: unknown[]) => {
    if (timer) return;
    timer = window.setTimeout(() => {
      fn(args);
      timer = null;
    }, delay);
  };
}

function mc(i: number) {
  return (i % CAT_COLOR_COUNT) + 1;
}

const COLOR_CACHE = {};
let COLOR_INDEX = 0;
export function bgcolor_mute_class(value) {
  if (typeof value === "string") {
    switch (value.toLowerCase()) {
      case "success":
      case "ready":
      case "normal":
      case "ok":
      case "good":
        return `bgcolor-success`;
      case "error":
      case "bad":
      case "failed":
      case "abnormal":
        return `bgcolor-error`;
      case "info":
        return `bgcolor-info`;
    }
  }
  return `bgcolor-cat-${(COLOR_CACHE[value + ""] ||= mc(COLOR_INDEX++))}-mute`;
}

export type StackBarSegment = {
  value: number;
  tooltip?: Renderable;
  color?: SMColor;
};

function StackedBar({ segments }: { segments: (number | StackBarSegment)[] }) {
  const norm = segments.map((s, i) =>
    typeof s === "object"
      ? {
          tooltip: s.tooltip,
          value: s.value,
          color: s.color ? `bgcolor-${s.color}` : `bgcolor-cat-${mc(i)}`,
        }
      : {
          tooltip: pcnt(s),
          value: s,
          color: `bgcolor-cat-${mc(i)}`,
        }
  );

  const total = sum(norm.map((s) => s.value));
  if (total === 0) return null;

  return (
    <div className="stacked_bar">
      <div className="stacked_bar_inner" style={{ width: pcnt(total) }}>
        {norm.map((s, i) => (
          <ToolTip
            key={i}
            className={s.color}
            message={s.tooltip}
            style={{ width: pcnt(s.value / total) }}
          />
        ))}
      </div>
    </div>
  );
}

export function pcnt(x: number) {
  return new Intl.NumberFormat([], {
    style: "percent",
  }).format(x);
}

export function sum(a: number[]) {
  return a.reduce((a, b) => a + b, 0);
}

export function px(x: number) {
  return x + "px";
}

function not_scalar(x: any) {
  switch (typeof x) {
    case "string":
    case "number":
    case "boolean":
      return false;
  }
  return true;
}

export function renderValue(value, format?, key?) {
  if (value == null) return null;

  // arrays
  if (Array.isArray(value)) {
    if (!value.length) return null;

    if (format === "stacked_bar") {
      return <StackedBar segments={value} />;
    }

    if (!format) {
      // TODO: click on more to expand list
      // or a tooltip
      const subset =
        value.length > 7
          ? [...value.slice(0, 5), `${value.length - 5} more…`]
          : value;
      const r = subset.map((v, i) => renderValue(v, "pill", i));
      return <div className="pills">{r}</div>;
    }

    const r = value.map((v, i) => renderValue(v, format, i));

    if (format === "pill") {
      return <div className="pills">{r}</div>;
    }

    if (value.length === 1) return r[0];

    switch (format) {
      case "paragraph":
      case "multiline":
      case "markdown":
        return (
          <ul>
            {r.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        );
    }

    // default
    return r.some(not_scalar) ? (
      <div className="pills">{r}</div>
    ) : (
      r.reduce((prev, curr) => [...prev, ", ", curr])
    );
  }

  const type = typeof value;

  // strings
  if (type === "string") {
    if (!value) return null;
    switch (format) {
      case "markdown":
        return (
          <RenderList
            content={[
              { type: "md", data: { unparsed: value, args: EMPTY_ARRAY } },
            ]}
          />
        );
      case "multiline":
      case "paragraph":
        return <span>{value}</span>;
      case "pre":
        return <pre>{value}</pre>;
      case "pill":
        return (
          <code key={key} className={cx("pill", bgcolor_mute_class(value))}>
            {value}
          </code>
        );
      case "link":
      case "_link":
      case "link_new_tab":
        return (
          <a key={key} target="_blank" href={value} onClick={stopProp}>
            {value}
          </a>
        );
      case "link_same_tab":
        return (
          <a key={key} href={value} onClick={stopProp}>
            {value}
          </a>
        );
      case "image":
        return (
          <div
            className="img_wrapper"
            key={key}
            style={{ backgroundImage: `url(${value})` }}
          />
        );
      case "avatar":
        return (
          <div
            className={cx(bgcolor_mute_class(value), "avatar")}
            key={key}
            style={{
              backgroundImage: `url(${value})`,
            }}
          />
        );
      case "icon":
        return (
          <span key={key} className="with_icon">
            <Icon icon={value} />
            {ZW_SPACE}
          </span>
        );
    }

    if (format in DATE_OPTIONS) {
      const parsed = new Date(value);
      // if the input was an ISO date...
      if (!isNaN(parsed.getTime()) && parsed.toISOString() === value) {
        return renderValue(parsed, format, key);
      }
    }

    return value;
  }

  if (type === "number") {
    switch (format) {
      case "pre":
        return <pre>{renderValue(value)}</pre>;
      case "pill":
        return (
          <code key={key} className={cx("pill", bgcolor_mute_class(value))}>
            {value}
          </code>
        );
      case "$":
      case "USD":
        return new Intl.NumberFormat([], {
          style: "currency",
          currency: "USD",
        }).format(value);
      case "€":
      case "EUR":
        return new Intl.NumberFormat([], {
          style: "currency",
          currency: "EUR",
        }).format(value);
      case "%":
        return value < 0.01
          ? new Intl.NumberFormat([], {
              style: "percent",
              maximumSignificantDigits: 1,
            }).format(value)
          : new Intl.NumberFormat([], {
              style: "percent",
            }).format(value);
      case "0.0%":
        if (value < 0.001) {
          return (
            "<" +
            new Intl.NumberFormat([], {
              style: "percent",
              maximumSignificantDigits: 1,
            }).format(0.001)
          );
        } else if (value < 0.01) {
          return new Intl.NumberFormat([], {
            style: "percent",
            maximumSignificantDigits: 1,
          }).format(value);
        }
        return new Intl.NumberFormat([], {
          style: "percent",
        }).format(value);

      case "progress":
        // TODO: support object version with tooltip
        return (
          <ToolTip message={pcnt(value)} inline>
            <progress value={value} />
          </ToolTip>
        );
      case "0.0":
        return new Intl.NumberFormat([], {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
        }).format(value);
      case "0.00":
        return new Intl.NumberFormat([], {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(value);
      case "0.000":
        return new Intl.NumberFormat([], {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        }).format(value);
      case "0,0":
        return new Intl.NumberFormat().format(value);
      case "hh:mm:ss": {
        // assume value is seconds
        const v = Math.abs(value);
        const hours = Math.floor(v / 3600);
        const mins = pad(Math.floor((v % 3600) / 60));
        const seconds = pad(Math.floor((v % 3600) % 60));
        const sign = value < 0 ? "-" : "";
        return hours
          ? `${sign}${hours}:${mins}:${seconds}`
          : `${sign}${mins}:${seconds}`;
      }
      case "human_byte": {
        const v = Math.abs(value);
        const match = byte_scales.find((scale) => v < scale.to)!;
        return new Intl.NumberFormat([], {
          maximumFractionDigits: 1,
          style: "unit",
          unit: match.unit,
          notation: "compact",
          unitDisplay: "narrow",
        }).format(value / match.size);
      }
      case "human_bit": {
        const v = Math.abs(value);
        const unit = bit_scales.find((scale) => v < scale.to)!;
        return new Intl.NumberFormat([], {
          maximumFractionDigits: 1,
          style: "unit",
          unit: unit.unit,
          notation: "compact",
          unitDisplay: "narrow",
        }).format(value / unit.size);
      }
      case "human_duration": {
        const duration = intervalToDuration({ start: 0, end: value * 1000 });

        // keep the first 2 non-zero components
        // TODO: should really keep up to the 2 significant ones.  ie: 1 year 8 seconds doesn't make sense.  should just be 1 year.
        const format = duration_scales
          .filter((scale) => duration[scale])
          .slice(0, 2);

        return formatDuration(duration, { format });
      }
      case "human_length": {
        const unit = length_scales.find((scale) => value < scale.to)!;

        return new Intl.NumberFormat([], {
          style: "unit",
          unit: unit.unit,
          unitDisplay: "short",
          maximumFractionDigits: 0,
        }).format(Math.round(value / unit.size));
      }
      case "human_mass": {
        const unit = mass_scales.find((scale) => value < scale.to)!;

        return new Intl.NumberFormat([], {
          style: "unit",
          unit: unit.unit,
          unitDisplay: "short",
          maximumFractionDigits: 0,
        }).format(Math.round(value / unit.size));
      }
      default:
        if (format in DATE_OPTIONS) {
          const date = new Date();
          date.setTime(value);
          return renderValue(date, format, key);
        }
        // assume currency format
        if (format && format.match(/^[A-Z]{3}$/)) {
          return new Intl.NumberFormat([], {
            style: "currency",
            currency: format,
          }).format(value);
        }
        return value;
    }
  }

  // booleans
  if (type === "boolean") {
    return value ? (
      <CheckSquare className="feather" />
    ) : (
      <Minus className="feather" />
    );
  }

  // dates
  if (value instanceof Date) {
    if (format === "human_date_relative") {
      return <RelativeDate date={value} />;
    }

    if (format === "pre") {
      return <pre>renderValue(value)</pre>;
    }

    const { dateStyle, timeStyle } =
      DATE_OPTIONS[format || "date_time_medium"] ||
      DATE_OPTIONS["date_time_medium"];

    const date = dateStyle ? (
      <span className="nowrap">
        {value.toLocaleDateString([], { dateStyle })}
      </span>
    ) : null;
    const time = timeStyle ? (
      <span className="nowrap">
        {value.toLocaleTimeString([], { timeStyle })}
      </span>
    ) : null;

    // TODO: Intl date format options
    return (
      <>
        {date}
        {time ? " " : null}
        {time}
      </>
    );
  }

  if (type === "object") {
    switch (format) {
      case "var":
        return renderValue(value.value, value.format);
      case "pre":
        return <pre>{JSON.stringify(value, null, 2)}</pre>;
      case "multiline":
        const { text, lines = 5 } = value;
        return <span style={{ WebkitLineClamp: lines }}>{text}</span>;
      case "link":
      case "_link":
      case "link_new_tab": {
        // TODO: normalize on value.label?
        const { label, title, href } = value;
        if ((label ?? title) && href) {
          return (
            <a key={key} target="_blank" href={href} onClick={stopProp}>
              {renderValue(label ?? title)}
            </a>
          );
        }
        return null;
      }
      case "link_same_tab": {
        // TODO: normalize on value.label?
        const { label, title, href } = value;
        if ((label ?? title) && href) {
          return (
            <a key={key} href={href} onClick={stopProp}>
              {renderValue(label ?? title)}
            </a>
          );
        }
        return null;
      }
      case "profile": {
        const { avatar, name, subtitle } = value;
        return (
          <Profile key={key} avatar={avatar} name={name} subtitle={subtitle} />
        );
      }
      case "avatar": {
        const { avatar, name, initials = first_alpha(name) } = value;
        return <Avatar key={key} avatar={avatar} initials={initials} />;
      }
      // case "message-in": {
      //   const { avatar, name, color, body, timestamp, busy } = value;

      //   return (
      //     <>
      //       <div
      //         className={cx(
      //           "message-bubble message-bubble-in",
      //           color && `bgcolor-${color}-mute`
      //         )}
      //       >
      //         {body}
      //         {busy && <div aria-busy="true"></div>}
      //         {timestamp && (
      //           <small>{renderValue(timestamp, "time_short")}</small>
      //         )}
      //       </div>
      //       <Avatar avatar={avatar} initials={name?.charAt(0)} />
      //     </>
      //   );
      // }

      // case "message-out": {
      //   const { avatar, name, color, body, timestamp, busy } = value;

      //   return (
      //     <>
      //       <div
      //         className={cx(
      //           "message-bubble message-bubble-out",
      //           color && `bgcolor-${color}-mute`
      //         )}
      //       >
      //         {body}
      //         {busy && <div aria-busy="true"></div>}
      //         {timestamp && (
      //           <small>{renderValue(timestamp, "time_short")}</small>
      //         )}{" "}
      //       </div>
      //       <Avatar avatar={avatar} initials={name?.charAt(0)} />
      //     </>
      //   );
      // }
      case "pill": {
        const { label, color, icon } = value;
        const ic = icon ? <Icon icon={icon} gap={label} /> : null;
        if (label || icon) {
          return (
            <code
              key={key}
              className={cx(
                "pill",
                color ? `bgcolor-${color}-mute` : bgcolor_mute_class(label)
              )}
            >
              {ic}
              {renderValue(label) ?? ZW_SPACE}
            </code>
          );
        }
        return null;
      }

      case "icon": {
        const { icon, tooltip } = value;
        return renderValue({ icon, tooltip }, "icon_label");
      }
      case "icon_label": {
        const { icon, label, indent, tooltip } = value;
        const lbl = renderValue(label);
        const ic = icon ? <Icon icon={icon} gap={lbl} /> : null;

        if (tooltip) {
          return (
            <ToolTip message={tooltip} inline>
              {renderValue({ icon, label, indent }, format)}
            </ToolTip>
          );
        }
        if (label || indent) {
          const i = indent ? (icon ? indent : indent + 1) : 0;
          return (
            <span className={cx("with_icon", i && `with_icon indent-${i}`)}>
              {ic}
              {lbl}
            </span>
          );
        } else {
          return ic;
        }
      }
      case "human_duration": {
        return formatDuration(value);
      }
    }
  }

  if (isMarkdown(value)) {
    return <RenderList content={[{ type: "md", data: value }]} />;
  }

  // everything else
  return stringify(value);
}

function first_alpha(str = "") {
  let match = str.match(/\p{L}/u);
  if (match) return match[0].toLocaleUpperCase();
  match = str.match(/\p{N}/u);
  if (match) return match[0];
  return "#";
}

function Profile({
  avatar,
  name,
  initials = first_alpha(name),
  subtitle,
}: {
  avatar?: string;
  name?: string;
  initials?: string;
  subtitle?: string;
}) {
  if (!(avatar || name || initials)) return null;

  return (
    <div className="profile_container">
      <Avatar avatar={avatar} initials={initials} />
      {name ? (
        <div className="nowrap title_subtitle" key="title">
          {name ? <div className="nowrap">{renderValue(name)}</div> : null}
          {subtitle ? <small>{renderValue(subtitle)}</small> : null}
        </div>
      ) : null}
    </div>
  );
}

export function Avatar({
  avatar,
  initials,
}: {
  avatar?: string;
  initials?: string;
}) {
  return avatar ? (
    <div
      className={cx("avatar", bgcolor_mute_class(initials || avatar))}
      key="avatar"
      data-initials={initials || "…"}
      style={{
        backgroundImage: `url(${avatar})`,
      }}
    />
  ) : initials ? (
    <div className={cx("avatar", bgcolor_mute_class(initials))} key="avatar">
      {initials}
    </div>
  ) : null;
}

const RTFInstance = new Intl.RelativeTimeFormat([], {
  numeric: "auto",
});

function formatToRelativeDate(date: Date) {
  const now = new Date();
  const diff = Math.round((date.getTime() - now.getTime()) / 1000);
  const abs_diff = Math.abs(diff);
  const unit = relative_time_scales.find((scale) => abs_diff < scale.to)!;

  return RTFInstance.format(Math.round(diff / unit.size), unit.unit);
}

function RelativeDate({ date }: { date: Date }) {
  const [formatted, setFormatted] = useState(formatToRelativeDate(date));

  // TODO: stop the timers if the page is not visible
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    const fn = () => {
      const now = new Date();
      const diff = Math.round((date.getTime() - now.getTime()) / 1000);
      const abs_diff = Math.abs(diff);
      const unit = relative_time_scales.find((scale) => abs_diff < scale.to)!;

      setFormatted(RTFInstance.format(Math.round(diff / unit.size), unit.unit));

      // don't refresh if more than one hour
      if (abs_diff >= 60 * 60) return;
      // one min or less, updated every sec
      if (abs_diff < 61) {
        const ms = now.getMilliseconds();
        timer = setTimeout(fn, (ms > 800 ? 2000 : 1000) - ms);
      } else {
        timer = setTimeout(fn, unit.size * 1000);
      }
    };

    fn();

    return () => clearTimeout(timer);
  }, [date]);

  return <span className="nowrap">{formatted}</span>;
}
