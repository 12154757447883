/* eslint-disable no-import-assign */
import React, { MouseEventHandler } from "react";
import * as FeatherIcon from "react-feather";
import { cx } from "./renderValue";
import { z } from "zod";

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || "";
const zEmoji = z.string().emoji();

export function Icon({
  icon,
  size = 24,
  className,
  gap,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  icon: string;
  size?: number;
  className?: string;
  gap?: any;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}) {
  if (!icon) return null;
  // TODO: handle emojis as icons

  const label_gap = gap != null ? "label_gap" : null;

  if (icon.match(/^(data|https?):/)) {
    return (
      <div
        className={cx(
          "icon",
          className,
          label_gap,
          onClick ? "clickable" : null
        )}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ backgroundImage: `url(${icon})` }}
      />
    );
  } else if (icon === "sm-busy") {
    return (
      <div
        className={cx("feather", className, label_gap)}
        aria-busy
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  } else if (zEmoji.safeParse(icon).success) {
    return (
      <div
        className={cx("feather", className, label_gap)}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {icon}
      </div>
    );
  } else {
    // TODO move this test first
    // build a map of arrow-left -> ArrowLeft
    icon = icon.split("-").map(capitalize).join("");
    return React.createElement(FeatherIcon[icon] || FeatherIcon.HelpCircle, {
      className: cx(
        "feather",
        className,
        label_gap,
        onClick ? "clickable" : null
      ),
      size,
      onClick,
      onMouseEnter,
      onMouseLeave,
    });
  }
}

function Last({
  size = 24,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  size?: number;
  className?: string;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <polyline points="7 18 13 12 7 6"></polyline>
      <line x1="17" y1="6" x2="17" y2="18"></line>
    </svg>
  );
}

function First({
  size = 24,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: {
  size?: number;
  className?: string;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <polyline points="17 18 11 12 17 6"></polyline>
      <line x1="7" y1="6" x2="7" y2="18"></line>
    </svg>
  );
}

// @ts-ignore
FeatherIcon.Last = Last;
// @ts-ignore
FeatherIcon.First = First;
