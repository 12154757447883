import { useEffect, useState } from "react";

export type DefinedUpdatable<T> = Readonly<{ pending: boolean; val: T }>;

export type Updatable<T> =
  | Readonly<{ pending: false; val: T }>
  | Readonly<{ pending: true; val: undefined | T }>;

export type MaybeUpdatable<T> = Updatable<T> | T;

export type Updating<T> = Updatable<T> | UpdatableInstance<T>;

type Listener<T> = (content: Updatable<T>, index: number) => void;

const EMPTY_PENDING: Updatable<any> = { pending: true, val: undefined };

/**
 * This is a super basic class for exposing a Subject
 */
export class UpdatableInstance<T> {
  updateIndex = 0;

  content: Updatable<T>;

  private nextListenerId = 1;
  private readonly listeners = new Map<number, Listener<T>>();

  constructor(initial: Updatable<T> = EMPTY_PENDING) {
    this.content = initial;
  }

  /**
   * Adds a listener that will be told every time the value updates
   * @param listener
   * @returns an unsubscribe method
   */
  addListener(listener: Listener<T>): () => void {
    const id = this.nextListenerId++;
    this.listeners.set(id, listener);
    return () => {
      this.listeners.delete(id);
    };
  }

  updateVal(val: T) {
    this.updateContent({ pending: false, val });
  }

  markPending() {
    // TODO: fix this
    // @ts-ignore
    this.updateContent({ pending: true, val: this.content.val });
  }

  updateContent(content: DefinedUpdatable<T>) {
    const prev = this.content;
    if (content.pending === prev.pending && content.val === prev.val) return;

    this.content = content;
    const index = ++this.updateIndex;

    // a simple way of properly handling listeners adding/removing themselves
    // during the iteration
    Array.from(this.listeners.keys()).forEach((id) => {
      const listener = this.listeners.get(id);
      listener?.(content, index);
    });
  }
}

export function useUpdatable<T>(
  obs: undefined | UpdatableInstance<T>
): Updatable<T> {
  const [state, setState] = useState(obs ? obs.content : EMPTY_PENDING);

  useEffect(() => {
    if (!obs) return;

    if (obs.content !== state) {
      setState(obs.content);
    }

    return obs.addListener(setState);
  }, [obs]);

  return state;
}

const prevVals = new WeakMap<any, Record<string, Updatable<any>>>();

export function useUpdatableProps(props: any): any {
  const resolved: any = {};

  const [, setState] = useState<unknown>();

  const unsubscribes: (() => void)[] = [];
  const rerender = () => {
    // passes a new value every time
    setState({});
  };

  const prev = prevVals.get(setState);
  let updatables: null | Record<string, Updatable<any>> = null;
  Object.keys(props).forEach((key) => {
    const prop = props[key];
    if (prop && prop instanceof UpdatableInstance) {
      if (!updatables) updatables = {};

      let content = prop.content;

      // if we are loading up a new value, then pass in the old one
      if (content.pending && prev && prev.hasOwnProperty(key)) {
        content = { pending: true, val: prev[key].val };
      }

      updatables[key] = content;
      resolved[key] = content;
      unsubscribes.push(prop.addListener(rerender));
    } else {
      resolved[key] = prop;
    }
  });

  useEffect(() => {
    if (updatables) {
      prevVals.set(setState, updatables);
    } else {
      prevVals.delete(setState);
    }

    return () => {
      unsubscribes.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  });

  return resolved;
}

/**
 * Use this for backwards compatibility of an updateable prop
 */
export function forceUpdateble<T>(val: Updatable<T[]> | T[]): Updatable<T[]> {
  return isUpdatable(val) ? val : { pending: false, val };
}

export function isUpdatable<T>(val: any): val is Updatable<T> {
  return typeof val === "object" && "pending" in val && "val" in val;
}
