import { useState } from "react";

export function areBytesEqual(
  a: undefined | Uint8Array,
  b: undefined | Uint8Array
): boolean {
  return a && b
    ? a.length === b.length && a.every((x, i) => x === b[i])
    : a === b;
}

export function useUUID() {
  return useState(() => crypto.randomUUID())[0];
}
