import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { LogLevel, LogLine } from "./protocol";
import { UpdatableInstance } from "./useUpdatableProps";
import stringify from "json-stringify-pretty-compact";

function renderValue(value, format = "string") {
  if (value == null) return null;

  // arrays
  const sep = format === "pill" ? " " : ", ";
  if (Array.isArray(value)) return stringify(value);

  // strings
  if (typeof value === "string") {
    switch (format) {
      case "pill":
        return <code>{value}</code>;
    }
    return value;
  }

  // dates
  if (value instanceof Date) {
    // TODO: Intl date format options
    return value.toLocaleDateString();
  }

  // everything else
  return stringify(value);
}
type IndexedLogLine = {
  index: number;
  line: LogLine;
};

class DevHubSingleton {
  private lineCount: number = 0;
  readonly latestLog = new UpdatableInstance<IndexedLogLine>();

  appendLogLine(line: LogLine) {
    const method = console[line.level];
    method.apply(
      console,
      Array.isArray(line.content)
        ? ["🏭", ...line.content]
        : ["🏭", line.content]
    );
    this.latestLog.updateVal({ index: this.lineCount++, line });
  }
}

export function devLog(msg: string, level: LogLevel = LogLevel.Log) {
  devHub().appendLogLine({ level, content: [msg] });
}

let singleton_: null | DevHubSingleton = null;
export function devHub(): DevHubSingleton {
  if (!singleton_) {
    singleton_ = new DevHubSingleton();
  }

  return singleton_;
}

function Log(props: { line: LogLine }) {
  const { level, content } = props.line;

  let icon: string;
  if (level === LogLevel.Info) {
    icon = "ℹ️";
  } else if (level === LogLevel.Warn) {
    icon = "⚠️";
  } else if (level === LogLevel.Error) {
    icon = "❌";
  } else {
    // level === LogLevel.Log
    icon = "📝";
  }

  return (
    <div>
      {icon}&nbsp;
      {content.map((c) => renderValue(c))}
    </div>
  );
}

export function DevHub() {
  const ref = useRef(null);
  const hub = devHub();
  const [logs, setLogs] = useState<IndexedLogLine[]>([]);
  useEffect(() => {
    return hub.latestLog.addListener((log) => {
      setLogs((logs) => [...logs, log.val]);
    });
  }, []);
  useLayoutEffect(() => {
    if (!ref.current) return;
    const el: HTMLElement = ref.current;
    // const diff = el.scrollHeight - el.scrollTop - el.clientHeight;
    // console.log({ diff });
    // if (diff < 10) return;
    // TODO: don't auto scroll if the user has touched scroll
    el.lastElementChild?.scrollIntoView({ behavior: "smooth" });
  });

  if (!logs.length) return null;

  return (
    <article className="dev_hub" ref={ref}>
      <div
        style={{
          textAlign: "right",
        }}
        onClick={(e) => setLogs([])}
      >
        clear {logs.length}
      </div>
      {logs.map(({ line, index }) => (
        <Log key={index} line={line} />
      ))}
    </article>
  );
}
