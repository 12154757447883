import React, { useState } from "react";
import { RenderList } from "../Markdown";
import { Icon } from "./Icon";
import { RowProps } from "../Row";
import { MarkdownDeclaration } from "../protocol";
import { ToolTip } from "./ToolTip";

type Props = {
  content: MarkdownDeclaration | string;
  icon?: string;
} & RowProps;

export function InfoIcon({ icon = "info", content }: Props) {
  const [show, setShow] = useState(false);
  const msg = show ? (
    typeof content === "string" ? (
      content
    ) : (
      <RenderList content={[{ type: "md", data: content }]} />
    )
  ) : undefined;
  return (
    <ToolTip message={msg} className="centered_tooltip" inline>
      <Icon
        icon={icon}
        onMouseEnter={(e) => setShow(true)}
        onMouseLeave={(e) => setShow(false)}
      />
    </ToolTip>
  );
}
